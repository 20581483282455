<template>
    <section class="desktop-navbar desktop-tabs" loading="lazy">
        <div class="container-fluid">
            <ul class="list-unstyled">

                <li class="nav-item">
                    <router-link :to="{name: 'Sports'}" class="cum-list home-padding" :class="{active : routeName == 'Sports' }"><b>Home</b></router-link>
                </li>

                <li class="nav-item" v-for="(sport, sIndex) in non_custom_sports" :key="sIndex">
                    <router-link :to="{name: 'Sports', params: {type: sport.slug, id: sport.id}}" class="cum-list" :class="{active : routeName == 'Sports' && $route.params.type == sport.slug }">
                        <b>{{ sport.name }}</b>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name: 'games'}" class="cum-list" :class="{active : routeName == 'games' }">
                        <span><b>casino</b></span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name: 'racing-category', params: {type: 'HORSE_RACE'}}" class="cum-list" :class="{active : routeName == 'racing-category' && $route.params.type == 'HORSE_RACE' }">
                        <span><b>Horse Racing</b></span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{name: 'racing-category', params: {type: 'GREY_HOUND'}}" class="cum-list" :class="{active : routeName == 'racing-category' && $route.params.type == 'GREY_HOUND' }">
                        <span><b> Greyhound Racing </b></span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link to="">
                        <span><b> fantasy cricket</b></span>
                    </router-link>
                </li>
               
                <li class="nav-item">
                    <router-link :to="{name: 'virtual-sports'}" class="cum-list" :class="{active : routeName == 'virtual-sports' }">
                        <span><b>virtual Sports</b></span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name: 'Matka'}" class="cum-list" :class="{active : routeName == 'Matka' }">
                        <span><b>matka</b></span>
                    </router-link>
                </li>

                <li class="nav-item" v-for="(sport, sIndex) in custom_sports" :key="sIndex">
                    <router-link :to="{name: 'Sports', params: {type: sport.slug, id: sport.id}}" class="cum-list" :class="{active : routeName == 'Sports' && $route.params.type == sport.slug }">
                        <b>{{ sport.name }}</b>
                    </router-link>
                </li>

            </ul>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "SportSelection",
    computed: {
        ...mapGetters(['sports_list']),
        routeName() {
            return this.$route.name
        },
        non_custom_sports (){
            return this.sports_list?.non_custom
        }, 
        custom_sports (){
            return this.sports_list?.custom
        } 
    },    
};
</script>


<style scoped>
.width-100 {
    width: 100% !important;
}
</style>